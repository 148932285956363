import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action/index';
import { AGE_VERFICATION_ACTION } from '../action/ageVerificationAction';
import END_POINTS from '../../service/EndPoint';
import { doPost } from '../../service';

export function* ageVerifiactionSaga(param) {
  const { params, callback } = param
  console.log("cecsdkjskdj",params)
  try {
    const response = yield doPost(END_POINTS.AGEVERIFICATION_URL, params)
    console.log('response',response)
    yield put(Actions.ageverficationSuccess(response))
    callback && callback(response)

  } catch (error) {
    console.log("error",error);
    callback({ error })
    yield put(Actions.ageverficationFailure(error))
  }
}


export default function* ageVerificationWatcher() {
  yield all([
    takeLatest(AGE_VERFICATION_ACTION, ageVerifiactionSaga),
  ]);
}
