import React from "react"
import { NavLink } from "react-router-dom"

const Sidebar = () => {
    return (
        <React.Fragment>
            <div className="col-auto col-md-3 col-xl-2 shadow-lg ">
                <div className="d-flex flex-column align-items-center align-items-sm-start  text-white min-vh-100">
                    <div className="my-3 w-100">
                        <img className="d-block mx-auto" src={require('../../asset/img/logo.png')} alt="logo" width="50" />
                    </div>
                    <ul className="nav nav-pills flex-column mb-sm-auto mb-0 w-100 align-items-center text-start align-items-sm-start" id="menu">
                        <li className="nav-item w-100 py-2">
                            <NavLink className={"nav-link"} to={"/"}><i className="fa-solid fa-gauge fa-xl"></i><span className="ms-2 d-none d-sm-inline">Capture</span></NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Sidebar