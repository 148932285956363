import { Actions } from '../action';

const initialState = {
    isLoading: false,
    token: null,
    user: null,
    error: false,
}
export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.LOGIN_USER: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.LOGIN_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                token: action?.data?.access_token,
                user: action?.data?.user,
                error: false
            };
        }
        case Actions.LOGIN_USER_FAILURE: {
            return {
                ...state,
                isLoading: false,
                token: null,
                user: null,
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export const getLoginData = (state) => state?.USER;
