import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './reducer/index';
import storage from 'redux-persist/lib/storage';
import sagaWatchers from './saga/index';
import logger from 'redux-logger';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['router'],
};

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, rootReducer);

console.log(persistedReducer,"persistedReducer");
export const store = configureStore({
    reducer: persistedReducer,
    middleware: [sagaMiddleware, logger],
    devTools: process.env.NODE_ENV !== 'production',
});


export const persistor = persistStore(store);

sagaMiddleware.run(sagaWatchers);