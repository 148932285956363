import { Actions } from '../action';

const initialState = {
    isLoading: false,
    verifiedImage: null,
    error: false,
}
export const ageVerificationReducer = (state = initialState, action) => {
    console.log('action',action)
    switch (action.type) {
        case Actions.AGE_VERFICATION_ACTION: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.AGE_VERFICATION_ACTION_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                verifiedImage: action?.base64Img,
                error: false
            };
        }
        case Actions.AGE_VERFICATION_ACTION_FAILURE: {
            return {
                ...state,
                isLoading: false,
                verifiedImage: null,
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export const getVerifedImage = (state) => state?.AGEVERIFICATION;
