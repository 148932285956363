import { doAction, doResponseAction } from ".";

const AGE_VERFICATION_ACTION = 'AGEVERFICATION_ACTION';
const AGE_VERFICATION_ACTION_SUCCESS = 'AGEVERFICATION_ACTION_SUCCESS';
const AGE_VERFICATION_ACTION_FAILURE = 'AGEVERFICATION_ACTION_FAILURE';

export {
    AGE_VERFICATION_ACTION,
    AGE_VERFICATION_ACTION_FAILURE,
    AGE_VERFICATION_ACTION_SUCCESS,
}

// AGE_VERFICATION
export const ageverfication = (params, callback) => doAction(AGE_VERFICATION_ACTION, params, callback);
export const ageverficationSuccess = (data) => doResponseAction(AGE_VERFICATION_ACTION_SUCCESS, data);
export const ageverficationFailure = (data) => doResponseAction(AGE_VERFICATION_ACTION_FAILURE, data);