import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import { Actions } from "../redux/action";
import { useDispatch } from "react-redux";
import moment from "moment";

const Home = () => {
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [verifiedImage, setVerifiedImage] = useState("");
  const [capturedImageList, setCapturedImageList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [image, setImage] = useState({})

  const webcamRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const getCameras = async () => {
      try {
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
          console.log("Camera access not supported in this browser");
        }

        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setCameras(videoDevices);
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
    };

    getCameras();
  }, []);

  const handleCameraSelect = (event) => {
    setSelectedCamera(event.target.value);
  };

  const handleViewDetails = (imageInfo) => {
    const prefixToAdd = "data:image/webp;base64,";
    const base64WithPrefix = prefixToAdd + imageInfo.base64img;
    setImage(base64WithPrefix);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  console.log("DD", capturedImageList);
  const captureImage = async () => {
    const imageSrc = webcamRef?.current?.getScreenshot();

    setCapturedImage(imageSrc);
    const callback = (res) => {
      console.log(res);

      const prefixToAdd = "data:image/webp;base64,";
      const base64WithPrefix = prefixToAdd + res.base64Img;
      setVerifiedImage(base64WithPrefix);

      res && res?.data?.length && res?.data?.map((item, index) => {
        item.base64img = res.base64Img;
        item.processingTimestamp = new Date()
        if (index == 0) {
          item.lengthOfPepole = res?.data?.length
        }
        setCapturedImageList(prevList => [...prevList, item])
      })
    };

    // Remove the prefix
    const prefixToRemove = "data:image/webp;base64,";
    const base64WithoutPrefix = imageSrc?.substring(prefixToRemove.length);
    let params = {
      image: base64WithoutPrefix,
    };
    dispatch(Actions.ageverfication(params, callback));
  };

  return (
    <div className="container">
      <h1 className="title">Age Verification</h1>
      <div className="selectDiv">
        <label htmlFor="cameraSelect" className="selectLabel">Select Camera:</label>
        <select id="cameraSelect" onChange={handleCameraSelect} value={selectedCamera || ""}>
          {cameras?.length > 0 ? cameras?.map((camera, index) => (
            <option key={index} value={camera.deviceId}>
              {camera.label || `Camera ${index + 1}`}
            </option>
          )) : <option disabled>No Camera found</option>}
        </select>
      </div>
      <div className="preview">
        <p className="previewLabel">Camera Preview:</p>
        <Webcam
          audio={false}
          videoConstraints={{
            deviceId: selectedCamera,
          }}
          ref={webcamRef}
          className="webcam"
        />
        <div>
          <button className="btn btn-primary captureBtn" onClick={() => captureImage()}>
            Capture Image
          </button>
        </div>
      </div>
      {capturedImage && (
        <div className="captureImageDiv">
          <p className="capturedImageLabel">Captured Image:</p>
          <div className="imageContainer">
            <img src={capturedImage} alt="Captured" className="captureImage" />
            {verifiedImage && <img src={verifiedImage} alt="Verified" className="captureImage" />}
          </div>
        </div>
      )}
      {capturedImageList?.length > 0 && (
        <div className="cameraList">
          <h4>Captured Image List</h4>
          <table>
            <thead>
              <tr>
                <th>Date and Time</th>
                <th>Age</th>
                <th>Gender</th>
                <th>View Image</th>
              </tr>
            </thead>
            <tbody>
              {capturedImageList.map((camera, index) => (
                <tr key={index}>
                  <td>{moment(camera?.processingTimestamp).format("DD/MM/YYYY hh:mm:ss:A")}</td>
                  <td>{camera?.age}</td>
                  <td>{camera?.gender}</td>
                  {camera?.lengthOfPepole && <td rowSpan={camera?.lengthOfPepole}><button className="viewButton" onClick={() => handleViewDetails(camera)}>View</button></td>}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={handleClosePopup}>&times;</span>
            <img src={image} alt="Verified" className="popupImage" />
            {/* Add more details here as needed */}
          </div>
        </div>
      )}

    </div>
  );
};

export default Home;
